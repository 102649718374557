import React from "react";

// Components
import Layout from "../components/Layouts/Layout";
import SEO from "../components/Utilities/SEO";
import FeatureLeavesEntry from "../components/Features/FeatureLeavesEntry";
import SliderVertical from "../components/Sliders/SliderVertical";
import SectionContentToggle from "../components/Sections/SectionContentToggle";
import SectionKnowBelieveFulfill from "../components/Sections/SectionKnowBelieveFulfill";
import { ProviderEntryUI } from "../contexts/context-entry-ui";
import SectionVideoBackground from "../components/Sections/SectionVideoBackground";
import SectionSocialSubscribe from "../components/Sections/SectionSocialSubscribe";
import ImgSlideTransition from "../components/Images/ImgSlideTransition";

const slides = [
  <SectionVideoBackground />,
  <SectionContentToggle />,
  <SectionKnowBelieveFulfill />,
  <SectionSocialSubscribe />
];

export default () => {
  return (
    <Layout scrollLock>
      <SEO
        title="Know Believe Fulfill | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <ProviderEntryUI>
        <FeatureLeavesEntry />
        <SliderVertical slides={slides} slideImage={<ImgSlideTransition />} />
      </ProviderEntryUI>
    </Layout>
  );
};
