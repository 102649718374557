/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import styled from "@emotion/styled";

// Components
import { css } from "@emotion/core";
import Container from "../Layouts/Container";
import Logo from "../Base/Logo";
import { mediaQueries, colors, fonts } from "../../configs/_configSite";
import { LinkMainInt } from "../Base/links/LinkMain";
import _configBase from "../../configs/_configBase";

const LinkMainIntE = styled(LinkMainInt)`
  color: ${colors.white};
  margin-right: 30px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: initial;

  &:hover {
    color: ${colors.gold};
  }

  @media (max-width: ${mediaQueries.sm}px) {
    margin: 0 15px;
  }
`;

const GridWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-center: center;
  height: 100%;
  @media (max-width: ${mediaQueries.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const d = new Date();

export default () => (
  <Container
    fluid
    background="#2F2F2F"
    cssProp={`
      position: relative;
      z-index: 10;
      height: 110px!important;
      padding: 0 30px;
      justify-content: space-between;
      @media(min-width: ${mediaQueries.md}px) {
        height: 90px!important;
      }
    `}
  >
    <GridWrapper>
      <div
        css={css`
          @media (max-width: ${mediaQueries.sm}px) {
            grid-column: 1 / span 3;
            text-align: center;
          }
        `}
      >
        <LinkMainIntE to="/terms-of-service">Terms of Service</LinkMainIntE>
        <LinkMainIntE to="/privacy-policy">Privacy Policy</LinkMainIntE>
      </div>
      <div
        css={css`
          @media (max-width: ${mediaQueries.sm}px) {
            grid-column: 1 / span 3;
            grid-row: 1 / 1;
          }
          text-align: center;
        `}
      >
        <Logo />
      </div>
      <p
        to=""
        css={css`
          ${_configBase};
          color: ${colors.white};
          font-size: 0.875rem;
          font-family: ${fonts.secondary};
          font-weight: 500;
          justify-self: right;
          @media (max-width: ${mediaQueries.sm}px) {
            text-align: center;
            grid-column: 1 / span 3;
            padding-bottom: 10px;
            width: 100%;
          }
        `}
      >
        Copyright © {d.getFullYear()}
      </p>
    </GridWrapper>
  </Container>
);
